import { useSelector } from 'react-redux'
import { useContext, useState } from 'react'
import { TicketItems } from '../../types'
import { SocketContext } from '../../websockets/SocketContext'
import { adaptMapToSend } from '../../helpers/manageMapForWebsocket'
import useProductsForClientMap from '../../hooks/useProductsForClientMap'
import { FRONT_URL_TO_SHARE, BACKEND_URL } from '../../config'
import { imgs } from '../../images'
import { WhatsappIcon, WhatsappShareButton } from 'react-share'

import './FloatingBarButtons.css'
import { FormattedMessage } from 'react-intl'


interface FloatingBarButtonsProps {
  setShowSummary: (s: boolean) => void
}

const FloatingBarButtons = ({ setShowSummary }: FloatingBarButtonsProps) => {
  const namesForClients: string[] = useSelector((s: any) => s.clientsName)
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const { connect } = useContext(SocketContext) as any
  const productsForClients = useProductsForClientMap()
  const sessionId: string = useSelector((state: any) => state.sessionId)
  const [copied, setCopied] = useState<boolean>(false)
  const url = FRONT_URL_TO_SHARE + 'load/' + sessionId

  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  const isMobileOrTablet = () => {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
  }

  const handleShare = async () => {
    try {
      window.open(`https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send/${encodeURIComponent(url)}`, '_blank')
      const messageObj = adaptMapToSend(productsForClients, namesForClients)
      const body = JSON.stringify({ list_of_clients: namesForClients, ticket, products_for_clients: messageObj })
      const headers = { 'Content-Type': 'application/json' }
      const options = { method: 'POST', headers, body }
      await fetch(`${BACKEND_URL}/session-info/` + sessionId, options)
    } catch (error) {
      console.error('Error sharing session', error)
    }
    connect('master', sessionId)
  }

  const handleCopy = async () => {
    navigator.clipboard.writeText(url)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
    await handleShare()
  }

  return (
    <div className="next-container">
      <button className="view-summary" disabled={false} onClick={() => setShowSummary(true)}>
        <FormattedMessage id="view-summary" />
      </button>

      <div className="floating-share-button">
        {isOpen && (
          <div className="box-buttons">
            <WhatsappShareButton url={`${url}`} onClick={handleShare}>
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>
            <button onClick={handleCopy}>
              {!copied ? <span></span> : <span>Copiado!</span>}
              <img src={imgs.clipboard} alt="clipboard" />
            </button>
          </div>
        )}
        <div className="share-img" onClick={() => toggleDropdown()}>
          <img src={imgs.shareLightBlue} alt="Share" className="share" />
        </div>
      </div>
    </div>
  )
}

export default FloatingBarButtons
